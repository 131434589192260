import React, { useState } from "react";
import "./style.scss";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import useAuth from "../../../../../hooks/useAuth";
import { setCommandProduct } from "../../../../../services/communication_apis";
import BDCpriceTable from "./BDCpriceTable";
import BDCidentificationTable from "./BDCidentificationTable";
import { formatPrix } from "../../../../../utils/dates";

const BDCproductCard = ({ product, onQuantityUpdate }) => {
  const [totalPrice, setTotalPrice] = useState(0); // total price
  const [showWarning, setShowWarning] = useState(false); // Warning show if selected price doesnt correspond to choosen quantity
  const { magasin } = useAuth();

  const [productCommand, setProductCommand] = useState({
    pbd_ligne: 0,
    mag_code: 0,
    bil_qte: 0,
    psp_cprospectus: 0,
    bil_paht: 0,
  });

  const prefix = "http://origine.inedis.fr/_fichier/_cmu/_bi_photos/"; // Prefix URL for image product
  const defaultImg = "https://semantic-ui.com/images/wireframe/image.png"; // Default image product

  /**
   * Select price for a given product and update the command for this product
   */
  const handleSelectPrice = async (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      await setCommandProduct({
        ...productCommand,
        [name]: value,
      });
      setProductCommand({
        ...productCommand,
        [name]: value,
      });
      checkSelectedPrice(product, parseFloat(value), productCommand.bil_qte);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   *  Set quantity on change (on front only, not in DB)
   */
  const handleChangeQuantity = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setProductCommand({
      ...productCommand,
      [name]: value,
    });
  };

  /**
   * Update the command for this product with the input quantity
   */
  const handleBlurQuantity = async () => {
    try {
      await setCommandProduct(productCommand);
      checkSelectedPrice(
        product,
        parseFloat(productCommand.bil_paht),
        parseInt(productCommand.bil_qte)
      );
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Check which price is selected and set a warning if quantity is not adequate to the selected price
   */
  const checkSelectedPrice = (product, paht, qte) => {
    let selectedPrice = "pbd_ht_promo"; // Selected price
    let showWarning = false; // Warning indicator

    // Pick select price option to display
    if (paht > 0 && qte > 0) {
      switch (paht) {
        case product.pbd_ht_1promo:
          selectedPrice = "pbd_ht_1promo";
          if (qte < parseInt(product.pbd_ht_1promo_unit)) {
            showWarning = true;
          }
          break;
        case product.pbd_ht_2promo:
          selectedPrice = "pbd_ht_2promo";
          if (qte < parseInt(product.pbd_ht_2promo_unit)) {
            showWarning = true;
          }
          break;
        case product.pbd_ht_3promo:
          selectedPrice = "pbd_ht_3promo";
          if (qte < parseInt(product.pbd_ht_3promo_unit)) {
            showWarning = true;
          }
          break;
        default:
          selectedPrice = "pbd_ht_promo";
          if (qte < parseInt(product.pbd_ht_promo_unit)) {
            showWarning = true;
          }
          break;
      }
    }
    setShowWarning(showWarning);
    return selectedPrice;
  };

  /**
   * Refresh on quantity change to stay synchronize with quantity in DB
   */
  React.useEffect(() => {
    let selectedPrice = checkSelectedPrice(
      product,
      product.pbd_ht_promo,
      product.bil_qte
    );

    const productCommandToSet = {
      pbd_ligne: product.pbd_ligne,
      mag_code: magasin.mag_code,
      bil_qte: product.bil_qte ?? 0,
      psp_cprospectus: product.psp_cprospectus ?? 0,
      bil_paht: product[selectedPrice] ?? 0,
    };
    setProductCommand(productCommandToSet);
  }, [magasin.mag_code, product]);

  /**
   * Refresh the total price on quantity or price change
   */
  React.useEffect(() => {
    if (productCommand.bil_paht) {
      setTotalPrice(productCommand.bil_paht * productCommand.bil_qte ?? 0);
    }
  }, [productCommand]);

  return (
    <div className="product-card">
      <div className="product-card__title">
        <h3> {product.con_nom} </h3>
      </div>
      <div className="product-card__head">
        <div className="product-card__img">
          <img
            src={`${prefix}${product.pbdp_nom_photo}`}
            class={`${prefix}${product.pbdp_nom_photo}`}
            alt="product img"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = defaultImg;
            }}
          />
        </div>
        <h3>{product.pbd_produit}</h3>
      </div>

      <div className="product-card__body">
        <div className="product-info">
          <div className="product-info__table">
            <div className="content">
              <BDCpriceTable product={product} />
            </div>
          </div>
          <div className="product-info__commentary">
            <div>
              {product.pbd_offre_affilie !== "0" ? (
                <>
                  <h4 className="title"> Commentaires</h4>
                  <p className="text-danger">{product.pbd_offre_affilie}</p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="product-info__conditioning">
            <div>
              <h4 className="title"> Conditionnements </h4>
              conditionnement&nbsp;minimum&nbsp;:&nbsp;
              {product.pbd_pcb}
              <br />
            </div>
            <div>
              {product.pbd_offre_consommateur !== "0" ? (
                <>
                  <h4 className="title"> Offre&nbsp;consommateur </h4>
                  <p className="text-danger">
                    {product.pbd_offre_consommateur}
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="product-info__reference">
            <BDCidentificationTable product={product} />
            <div>
              <div className="depliant">
                <div> Présence dépliant </div>
                <ul>
                  {product.pagination ? (
                    product.pagination.map((pagination) => {
                      return (
                        <>
                          <li>{pagination.ps_p_libelle} </li>
                        </>
                      );
                    })
                  ) : (
                    <li> Non </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-card__footer">
        <div className="panier-detail">
          <div className="panier-detail__prix-uni">
            <label className="select">
              <select
                className="select__field"
                name="bil_paht"
                onChange={(e) => handleSelectPrice(e)}
                value={productCommand.bil_paht}
              >
                <option value={product.pbd_ht_promo}>
                  {formatPrix(product.pbd_ht_promo)}
                </option>

                {product.pbd_ht_1promo > 0 && (
                  <option value={product.pbd_ht_1promo}>
                    {formatPrix(product.pbd_ht_1promo)}
                  </option>
                )}
                {product.pbd_ht_2promo > 0 && (
                  <option value={product.pbd_ht_2promo}>
                    {formatPrix(product.pbd_ht_2promo)}
                  </option>
                )}
                {product.pbd_ht_3promo > 0 && (
                  <option value={product.pbd_ht_3promo}>
                    {formatPrix(product.pbd_ht_3promo)}
                  </option>
                )}
              </select>
              <span className="select__label">
                <div className="label">
                  <p>Prix unité</p>
                  {showWarning && (
                    <>
                      <span
                        className="warning-icon"
                        title="Le prix selectionné ne correspond pas à la quantité saisie"
                      >
                        <WarningAmberIcon />
                        &nbsp;quantité insuffisante
                      </span>
                    </>
                  )}
                </div>
              </span>
            </label>
          </div>
          <div className="panier-detail__quantity">
            <label className="input">
              <input
                className="input__field"
                placeholder=" "
                min={0}
                name="bil_qte"
                value={productCommand.bil_qte}
                onChange={(event) => handleChangeQuantity(event)}
                onBlur={handleBlurQuantity}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <span className="input__label">Quantité</span>
            </label>
          </div>

          <div className="panier-detail__total">
            {totalPrice > 0 && (
              <>
                <div className="total-price">{formatPrix(totalPrice)}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BDCproductCard;
