export const DOMAIN = {

/// CONFIG DEVELOPPEMENT

// URL:'https://origine.inedis.fr',

// URL_API:'http://192.168.111.114:8000/api/',

// URL_WEB:'http://192.168.111.114:8000/',

// URL_APP:'http://192.168.111.114:3000/',

//config de production


URL: 'https://origine.inedis.fr/',

URL_API: 'https://api.inedis.fr/api/',

URL_WEB: 'https://api.inedis.fr/',

URL_APP: 'https://extranet.inedis.fr/',

};

export const STATUS = {
    MAINTENANCE:false
};