import React, { createContext, useContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }
  }, []);

  useEffect(() => {
    console.log("Updating localStorage with:", cartItems);
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const removeFromCart = (pr_code, taille_id, couleur_id, logo_id) => {
    setCartItems((prevItems) =>
      prevItems.filter(
        (item) =>
          item.pr_code !== pr_code ||
          item.taille_id !== taille_id ||
          item.couleur_id !== couleur_id ||
          item.logo_id !== logo_id
      )
    );
  };
  const updateQuantity = (pr_code, taille_id, couleur_id, logo_id, amount) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        if (
          item.pr_code === pr_code &&
          item.taille_id === taille_id &&
          item.couleur_id === couleur_id &&
          item.logo_id === logo_id
        ) {
          return { ...item, quantite: Math.max(1, item.quantite + amount) };
        }
        return item;
      })
    );
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        removeFromCart,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
